// import React from 'react';
import { StoreContext } from './store.context';
// import { AssistantObject } from '../../../api/src/app/analyseDoc/openai/openai.objects';
// import { ThreadModel } from '../../../api/src/app/analyseDoc/infrastructure/threads/thread.model';
// import { RunModel } from '../../../api/src/app/analyseDoc/infrastructure/runs/run.model';
// import { FileModel } from '../../../api/src/app/analyseDoc/infrastructure/files/file.model';

const StoreProvider = ({ children }: { children: React.ReactNode }) => {
  //   const assistants = useStoreEntry<AssistantObject[]>();
  //   const threads = useStoreEntry<ThreadModel[]>();
  //   const runs = useStoreEntry<RunModel[]>();
  //   const files = useStoreEntry<FileModel[]>();

  return (
    // <StoreContext.Provider value={{ assistants, threads, runs, files }}>
    <StoreContext.Provider value={undefined}>
      {children}
    </StoreContext.Provider>
  );
};

export default StoreProvider;