import { useCallback, useEffect, useState } from "react";
import TimeLineGraph from "../components/TimeLineGraph";
import { axiosInstance } from "../utils/axiosInstance";
import { useAuth } from "../store/auth.context";

const years = [2023, 2024];
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

function Analytics() {
  const { auth } = useAuth();
  const [conversationData, setConversationData] = useState<Array<{ date: string, value: number }>>([]);
  const [importData, setImportData] = useState<Array<{ date: string, value: number }>>([]);
  const [mailData, setMailData] = useState<Array<{ date: string, value: number }>>([]);
  const [loading, setLoading] = useState(false);

  const [mode, setMode] = useState<'year' | 'month'>('month');
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [month, setMonth] = useState<string>(months[new Date().getMonth()]);

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const selectedMonth = mode === 'month' ? months.indexOf(month) + 1 : '';
      const url = `/analytics/${mode === 'month' ? 'mensualReport' : 'annualReport'}?year=${year}${mode === 'month' ? `&month=${selectedMonth}` : ``}`;
      const response = await axiosInstance.get(url, {
        headers: {
          'Authorization': `Bearer ${auth.data?.token}`,
        }
      });
      const newData = response.data;

      setConversationData(newData.conversationReport || []);
      setImportData(newData.analyzeReport || []);
      setMailData(newData.mailGeneratorReport || []);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [mode, year, month, auth.data?.token]);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      fetchData();
    }

    return () => {
      mounted = false;
    }
  }, [fetchData]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "16px",
          marginLeft: "83px"
        }}
      >
        <h3>
          <pre>Settings tab</pre>
        </h3>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <button
            onClick={() => setMode('month')}
            style={{
              backgroundColor: mode === 'month' ? '#383838' : '',
            }}
          >
            Monthly
          </button>
          <button
            onClick={() => setMode('year')}
            style={{
              backgroundColor: mode === 'year' ? '#383838' : '',
            }}
          >
            Annual
          </button>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "16px",
          }}
        >
          <select
            onChange={(e) => setYear(Number(e.target.value))}
            value={year}
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
          {mode === 'month' && (
            <select
              onChange={(e) => setMonth(months[Number(e.target.value) - 1])}
              value={months.indexOf(month) + 1}
            >
              {months.map((month, index) => (
                <option key={index} value={index + 1}>
                  {month}
                </option>
              ))}
            </select>
          )}
        </div>

        <button onClick={() => {
          fetchData();
        }}>Get Report</button>
      </div>

      {loading && <p>Loading...</p>}

      <div
        style={{
          display: "flex",
          flexDirection: `${mode === 'year' ? 'column' : 'row'}`,
          justifyContent: "space-around",
        }}
      >
        {conversationData.length > 0 && <TimeLineGraph data={conversationData} width={"100%"} title="ChatBot"></TimeLineGraph>}
        {importData.length > 0 && <TimeLineGraph data={importData} width={"100%"} title="Import Intelligent"></TimeLineGraph>}
        {mailData.length > 0 && <TimeLineGraph data={mailData} width={"100%"} title="Génération de mails"></TimeLineGraph>}
      </div>
    </div >
  );
}


export default Analytics;