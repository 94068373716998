// import { AssistantObject } from '../../../api/src/app/analyseDoc/openai/openai.objects';
import { createContext, useContext } from "react";
// import { ThreadModel } from '../../../api/src/app/analyseDoc/infrastructure/threads/thread.model';
// import { RunModel } from '../../../api/src/app/analyseDoc/infrastructure/runs/run.model';
// import { FileModel } from '../../../api/src/app/analyseDoc/infrastructure/files/file.model';


// export enum FetchStatus {
//   INIT = 'init',
//   LOADING = 'loading',
//   LOADED = 'loaded',
//   ERROR = 'error',
// }

// export function useStoreEntry<T>() {
//   const [data, setData] = useState<T>();
//   const [status, setStatus] = useState<FetchStatus>(FetchStatus.INIT);

//   const reset = () => {
//     setData(undefined);
//     setStatus(FetchStatus.INIT);
//   };

//   return {
//     data,
//     setData,
//     status,
//     setStatus,
//     reset,
//   };
// }

// export type AssistantStoreEntry = ReturnType<typeof useStoreEntry<AssistantObject[]>>;
// export type ThreadStoreEntry = ReturnType<typeof useStoreEntry<ThreadModel[]>>;
// export type RunStoreEntry = ReturnType<typeof useStoreEntry<RunModel[]>>;
// export type FileStoreEntry = ReturnType<typeof useStoreEntry<FileModel[]>>;

export const StoreContext = createContext<undefined>(undefined);
//   assistants: AssistantStoreEntry;
//   threads: ThreadStoreEntry;
//   runs: RunStoreEntry;
//   files: FileStoreEntry;
// } | undefined>(undefined);

export function useStore() {
  const context = useContext(StoreContext);
  if (!context) {
    throw new Error('useStore must be used within a StoreContext.Provider');
  }
  return context;
}

// export const useAssistantStore = () => {
//   const { assistants } = useStore();

//   const updateAssistantsInStore = (assistantList: AssistantObject[]) => {
//     assistantList.forEach(assistant => {
//       assistants.setData(current =>
//         current?.find(a => a.id === assistant.id)
//           ? current.map(a => a.id === assistant.id ? assistant : a)
//           : current ? [...current, assistant] : [assistant]
//       );
//     });
//   };

//   return {
//     ...assistants,
//     updateAssistantsInStore,
//   };
// };

// export const useThreadStore = () => {
//   const { threads } = useStore();

//   const sortThreads = (threads: ThreadModel[]) => {
//     return threads.sort((a, b) => {
//       const aDate = new Date(a.updatedAt);
//       const bDate = new Date(b.updatedAt);
//       return bDate.getTime() - aDate.getTime();
//     });
//   }

//   const updateThreadsInStore = (threadList: ThreadModel[]) => {
//     threadList.forEach(thread => {
//       threads.setData(current =>
//         current?.find(t => t.id === thread.id)
//           ? current.map(t => t.id === thread.id ? thread : t)
//           : current ? [...current, thread] : [thread]
//       );
//     });
//     threads.setData(current => sortThreads(current || []));
//   };

//   const AddMessageToThreadInStore = (thread: ThreadModel, message: any) => {
//     threads.setData(current => {
//       const threadToUpdate = current?.find(t => t.id === thread.id);
//       if (threadToUpdate) {
//         threadToUpdate.messages.push(message);
//         return current?.map(t => t.id === thread.id ? threadToUpdate : t);
//       }
//       return current;
//     })
//   }

//   const addThreadInStore = (thread: ThreadModel) => {
//     threads.setData(current =>
//       current ? [...current, thread] : [thread]
//     );
//     threads.setData(current => sortThreads(current || []));
//   }

//   const deleteThreadInStore = (thread: ThreadModel) => {
//     threads.setData(current =>
//       current?.filter(t => t.id !== thread.id)
//     );
//   }

//   return {
//     ...threads,
//     updateThreadsInStore,
//     addThreadInStore,
//     AddMessageToThreadInStore,
//     deleteThreadInStore,
//   };
// };

// export const useRunStore = () => {
//   const { runs } = useStore();

//   const updateRunsInStore = (runList: RunModel[]) => {
//     runList.forEach(run => {
//       runs.setData(current =>
//         current?.find(r => r.id === run.id)
//           ? current.map(r => r.id === run.id ? run : r)
//           : current ? [...current, run] : [run]
//       );
//     });
//   };

//   const addRunInStore = (run: RunModel) => {
//     runs.setData(current =>
//       current ? [...current, run] : [run]
//     );
//   };

//   return {
//     ...runs,
//     updateRunsInStore,
//     addRunInStore
//   };

// };

// export const useFileStore = () => {
//   const { files } = useStore();

//   const addFileInStore = (file: FileModel) => {
//     files.setData(current =>
//       current ? [...current, file] : [file]
//     );
//   }

//   return {
//     ...files,
//     addFileInStore
//   };

// }