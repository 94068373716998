export type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
export type OnNextMethod<Result> = (_nextValue: Result) => void;
export type OnCompleteMethod = () => void;
export type Body = string | FormData | null;

export function appendContentType(body: Body): HeadersInit {
  if (body) {
    // if a body is present
    if (body instanceof FormData) {
      // don't specify content type if it is a FormData
      return {};
    }
    return { 'Content-Type': 'application/json' }; // default
  }
  return {}; // no body, no content type
}

export function appendBody(body: Body): RequestInit {
  return (body && { body }) || {};
}

export const apiUrl = import.meta.env.VITE_URL_API;

export function fetchStream(
  endPoint: string,
  httpMethod: HttpMethod,
  body: Body,
  needAuthorizationHeader = false,
  onRead: OnNextMethod<Uint8Array | undefined> | null = null,
  onComplete: OnCompleteMethod | null = null,
  authToken: string | null = null
) {
  const myHeaders = new Headers({
    ...appendContentType(body),
    ...(needAuthorizationHeader && authToken && { 'Authorization': `Bearer ${authToken}` })
  });

  return fetch(endPoint.startsWith('http') ? `${endPoint}` : `${apiUrl}${endPoint}`, {
    method: httpMethod,
    headers: myHeaders,
    ...appendBody(body)
  })
    .then(response => {
      const reader = response?.body?.getReader();
      if (!reader) return;
      const read = () => {
        void reader.read().then(({ done, value }) => {
          if (done) {
            if (onComplete) {
              onComplete();
            }
            return;
          }
          if (onRead) {
            onRead(value);
          }
          read();
        });
      };
      read();
    })
    .catch(err => {
      console.error('fetchStream', err);
      throw err;
    });
}

