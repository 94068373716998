import React from 'react';
import { AuthContext, AuthModel, useStoreEntry } from './auth.context';

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const auth = useStoreEntry<AuthModel>();
  return (
    <AuthContext.Provider value={{ auth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;