import React, { useState } from 'react';
import { useAuth } from '../store/auth.context';
import { axiosInstancePTF } from '../utils/axiosInstance';

function Login() {
  const { auth } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const res = await axiosInstancePTF.post(`/lyaptf/api/authenticate`, {
      identifiant: username,
      password: password
    });
    if (res.status !== 200) {
      throw new Error('There was an error fetching the analyzes');
    }
    if (!res.data.body.roles.includes('ACTE_ADMIN_LYA')) {
      setErrorMessage('You are not authorized to access this part of the service')
      return;
    }
    auth.setData({
      token: res.data.body.token,
      isAuthenticated: true
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label>
          Username:
          <input type="text" value={username} onChange={handleUsernameChange} />
        </label>
        <br />
        <label>
          Password:
          <input type="password" value={password} onChange={handlePasswordChange} />
        </label>
        <br />
        <button type="submit">Login</button>
      </form>
      <div>{errorMessage}</div>
    </div>
  );
}

export default Login;