import { createContext, useContext, useState } from "react";

export enum FetchStatus {
  INIT = 'init',
  LOADING = 'loading',
  LOADED = 'loaded',
  ERROR = 'error',
}

export interface AuthModel {
  isAuthenticated: boolean;
  token: string;
}

export function useStoreEntry<T>() {
  const [data, setData] = useState<T>();
  const [status, setStatus] = useState<FetchStatus>(FetchStatus.INIT);

  const reset = () => {
    setData(undefined);
    setStatus(FetchStatus.INIT);
  };

  return {
    data,
    setData,
    status,
    setStatus,
    reset,
  };
}

export type AuthStoreEntry = ReturnType<typeof useStoreEntry<AuthModel>>;

export const AuthContext = createContext<{
  auth: AuthStoreEntry;
  
} | undefined>(undefined);

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useStore must be used within a StoreContext.Provider');
  }
  return context;
}

export const useAuthStore = () => {
  const { auth } = useAuth();

  const login = (token: string) => {
    auth.setData({
      isAuthenticated: true,
      token
    });
    auth.setStatus(FetchStatus.LOADED);
  }

  return {
    ...auth,
    login
  };
}