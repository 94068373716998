import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './views/Home';
import Support from './views/Support';
import styles from './App.module.css';
import { useAuth } from './store/auth.context';
import Login from './views/Login';
import Analytics from './views/Analytics';
import { axiosInstance } from './utils/axiosInstance';
import JeremAI from './views/JeremAI';

function App() {
  const { auth } = useAuth();
  const documentationPath = `${axiosInstance.defaults.baseURL}/documentation`;

  return (
    <Router>
      <nav>
        <Link to='/'>
          <img className="logo" src="https://rec-ptf-api.interne.app.dismoilya.fr/img/Logo_LYA_x1.png" />
        </Link>
        <h2>Lya AI service</h2>
        <Link className={styles.navButton} to={documentationPath} target='_blank'>Documentation</Link>
        <Link className={styles.navButton} to="/support">Support</Link>
        <Link className={styles.navButton} to="/analytics">Analytics</Link>
        {/* <Link className={styles.navButton} to='/assistant'>Assistants</Link> */}
        <Link className={styles.navButton} to='/supportAI'>JeremAI & ThomAI</Link>
      </nav>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/support" element={auth.data?.isAuthenticated ? <Support /> : <Login />} />
        <Route path="/analytics" element={auth.data?.isAuthenticated ? <Analytics /> : <Login />} />
        <Route path="/supportAI" element={auth.data?.isAuthenticated ? <JeremAI /> : <Login />} />
      </Routes>
    </Router >
  )
}

export default App;
