function Home() {
  const apiUrl = import.meta.env.VITE_URL_API;

  return (
    <div>
      <h3>Home page</h3>
      <span>Api url: {`${apiUrl}`}</span>
    </div>
  );
}

export default Home;
