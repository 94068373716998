import { useCallback, useState } from 'react';
import '../App.css'
import AnalyzeResult from '../components/support/analyzeResult';
import { useAuth } from '../store/auth.context';
import { axiosInstance } from '../utils/axiosInstance';

interface Filters {
  identifiant: string,
  codeDistrib: string,
  startDate: string,
  endDate: string
}

export interface AnalyzeDTO {
  id: string;
  identifiant: string;
  codeDistrib: string;
  query: string;
  type: 'personne' | 'entreprise';
  response: string;
  updatedAt: Date;
  createdAt: Date;
}

function Support() {
  const { auth } = useAuth();
  const [fetched, setFetched] = useState(false);
  const [filters, setFilters] = useState<Filters>({
    identifiant: '',
    codeDistrib: '',
    startDate: '',
    endDate: ''
  })

  const [analyzes, setAnalyzes] = useState<AnalyzeDTO[]>([]);

  const fetchAnalyzes = useCallback(async () => {
    const query = Object.entries(filters).filter(([, value]) => value).map(([key, value]) => `${key}=${value}`).join('&')

    const response = await axiosInstance.get(`/support/analyzes?${query}`, {
      headers: {
        'Authorization': `Bearer ${auth.data?.token}`
      }
    });

    if (response.status !== 200) {
      throw new Error('There was an error fetching the analyzes');
    }

    const responseJson = response.data;
    setAnalyzes(responseJson);
    setFetched(true);
  }, [filters, auth.data?.token]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (e: any) => {
    setFilters({ ...filters, [e.target.name]: e.target.value })
  }

  return (
    <div>
      <div className='filterPart'>
        <label htmlFor="identifiant">Mail:</label>
        <input
          value={filters.identifiant}
          onChange={handleChange}
          type="text"
          id="identifiant"
          name="identifiant"
        />
        <label htmlFor="codeDistrib">Code Distrib:</label>
        <input
          value={filters.codeDistrib}
          onChange={handleChange}
          type="text"
          id="codeDistrib"
          name="codeDistrib"
        />
        <label htmlFor="startDate">Start Date:</label>
        <input
          value={filters.startDate}
          onChange={handleChange}
          type="date"
          id="startDate"
          name="startDate"
        />
        <label htmlFor="endDate">End Date:</label>
        <input
          value={filters.endDate}
          onChange={handleChange}
          type="date"
          id="endDate"
          name="endDate"
        />
        <button type="submit" className="submit-btn" onClick={(e) => { e.preventDefault(); void fetchAnalyzes(); }}>
          Search
        </button>
      </div>
      <div>
        {analyzes
          .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
          .map((analyze: AnalyzeDTO) => (
            <AnalyzeResult key={analyze.id} analyze={analyze} />
          ))}
        {fetched && analyzes.length === 0 && <p>No analyzes found</p>}
      </div>
    </div >
  )
}

export default Support;
