import { AnalyzeDTO } from '../../views/Support';
import styles from './analyzeResult.module.css';

interface AnalyzeResultProps {
  analyze: AnalyzeDTO;
}

function AnalyzeResult({ analyze }: AnalyzeResultProps) {
  const obj = JSON.parse(analyze.response);
  const entries = Object.entries(obj);
  const date = new Date(analyze.createdAt);

  return (
    <div className={styles.card}>
      <div className={styles.info}>
        <p>{analyze.type}</p>
        <p>{analyze.codeDistrib}</p>
        <p>{analyze.identifiant}</p>
        <p>
          {
            `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` +
            ` ` +
            `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
          }
        </p>
      </div>
      <div className={styles.content}>
        <pre className={styles.query}>{analyze.query}</pre>
        <div>{`>`}</div>
        <div className={styles.response}>
          {entries.map(([key, value]) => (
            <div className={styles.responseLine} key={key}>
              <span>{key}:</span>
              <span>{JSON.stringify(value)}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AnalyzeResult;