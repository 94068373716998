import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

interface TimeLineGraphProps {
  data: {
    date: string;
    value: number;
  }[];
  title?: string;
  width?: string | number;
  height?: string | number;
}

function CustomTooltip({ active, payload, label }: any) {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: "white", padding: "0 10px", border: "1px solid #ccc", color: '#181818' }}>
        <div><b>{label}</b></div>
        <div>Interactions: {payload[0].value}</div>
      </div>
    );
  }

  return null;
}



function TimeLineGraph({ data, title, width, height }: TimeLineGraphProps) {
  const numberOfInteractions = data.reduce((acc, item) => acc + item.value, 0);

  return (
    <ResponsiveContainer
      width={width || "100%"}
      height={height || 300}
    // style={{ backgroundColor: "white", boxShadow: "0 0 8px 0 #ccc" }}
    >
      <>
        {title &&
          <span>
            <span><b>{title}</b></span>
            <span style={{ marginLeft: 8, color: "#888" }}>({numberOfInteractions})</span>
          </span>

        }
        <ResponsiveContainer>
          <LineChart
            data={data}
            margin={{
              top: 5, right: 30, left: 20, bottom: title ? 50 : 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date"
              interval={"preserveStartEnd"}
              tickFormatter={
                (value: string) => {
                  const date = new Date(value);
                  return `${date.getDate()}/${date.getMonth() + 1}`;
                }
              }
            />
            <YAxis />
            <Tooltip content={
              <CustomTooltip />
            } />
            <Line type="monotone" dataKey="value" stroke="#8884d8" />
          </LineChart>
        </ResponsiveContainer>
      </>
    </ResponsiveContainer>
  );
}

export default TimeLineGraph;