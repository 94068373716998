import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: `${import.meta.env.VITE_SCHEME_API}://${
    import.meta.env.VITE_URL_API
  }`,
});

export const axiosInstancePTF = axios.create({
  baseURL: `https://${import.meta.env.VITE_URL_PTF}`,
});