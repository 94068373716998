import { useCallback, useState } from "react";
import { useAuth } from "../store/auth.context";
import { fetchStream } from '../utils/fetch';
import { axiosInstance } from "../utils/axiosInstance";

function JeremAI() {
  const { auth } = useAuth();
  const [messages, setMessages] = useState<string[]>([]);
  const apiUrl = axiosInstance.defaults.baseURL;

  const decoder = new TextDecoder('utf-8');

  const onRead = (data: any) => {
    const decodedData = decoder.decode(data);
    console.log(decodedData);
    const splittedMessages = decodedData.split('\n');
    setMessages((prev) => [...prev, ...splittedMessages]);
  };

  const onComplete = useCallback(() => {
  }, []);

  const handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.files);
    if (!event.target.files) return;
    const file = event.target.files[0];

    const formData = new FormData();
    formData.append('file', file);
    formData.append('filename', file.name);


    try {
      await fetchStream(`${apiUrl}/jeremAI/ingest`, 'POST', formData, true, onRead, onComplete, auth.data?.token);

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <h1>JeremAI & ThomAI</h1>

      <input id='fileUpload' type="file" onChange={handleUpload} style={{ visibility: 'hidden' }} />
      <div>
        <label
          htmlFor='fileUpload'
          style={{ margin: '16px', padding: '10px', cursor: 'pointer', backgroundColor: 'orange', borderRadius: "8px" }}
        >Import hubspot .csv</label>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <div style={{
          display: 'flex',
          alignItems: "flex-start",
          paddingLeft: '80px',
          flexDirection: 'column-reverse',
          marginTop: '20px',
          width: '50%',
          textWrap: 'pretty',
          overflow: 'hidden'
        }}>
          {messages.map((message, key) => (
            <div key={key}>{message}</div>
          ))}
        </div>
      </div>

    </div>
  );
}

export default JeremAI;